/* global process */
/*eslint fp/no-mutation:0*/
const santaOptions = require('wix-santa/dist/options')
const {unpkgOrNode} = require('santa-main-r/lib/lib/common/rjs-config-utils')
const getViewerRjsConfig = require('santa-main-r/lib/lib/viewer/get-rjs-config').default
const PackagesUtil = require('santa-main-r/lib/lib/common/PackagesUtil').default
const options = require('../gen/options')
const packagesList = require('wix-santa/dist/packagesList.json') // TODO get from node_modules/wix-santa/packages-bin/hashManifest.json?

/**
 * @param {string} boltBase
 * @param {string} santaBase santa base url or local path
 * @param serviceTopology
 * @param {object} configOptions
 * @returns {*}
 */
const getRjsConfig = assetName => (boltBase, santaBase, serviceTopology,
    {local = false, santaPackagesToDebug = [], debug = false} = {}) => {
    const santaConf = getViewerRjsConfig(santaOptions.versions, serviceTopology, local)

    /**
     * @type {function(pkg:string, path:string)}
     */
    const _unpkg = unpkgOrNode.bind(null, options.versions, local)

    const pr = process.env.DEV_SERVER === 'true' ? '' : 'bolt-main/app/'
    const mode = debug ? 'devel' : 'prod'

    const config = {
        baseUrl: boltBase,
        paths: {
            'bolt-main': `${pr}${assetName}-${mode}`,
            'bolt-components': _unpkg('bolt-components', 'dist/bolt-components'),
            SoundManager: _unpkg('soundmanager2', 'script/soundmanager2-nodebug-jsmin')
        },
        map: {
            '*': {
                'react-dom': 'reactDOM',
                imageClientApi: 'image-client-api',
                imageClientLib: 'image-client-api'
            }
        },
        shim: {
            color: {exports: 'Color'}
        }
    }

    const debugQuery = santaPackagesToDebug.length > 0 ? `debug=${santaPackagesToDebug.join(',')}` : ''
    const packagesUtil = new PackagesUtil(packagesList, debugQuery)
    const config2 = packagesUtil.buildConfig(santaConf)

    // console.log(config2)
    // const santaPaths = Object.assign({}, santaConf.paths, config2.paths)
    // Object.keys(santaPaths)
    //     .filter(k => typeof santaPaths[k] === 'object' && !Array.isArray(santaPaths[k]))
    //     .forEach(k => {santaPaths[k] = santaPaths[k][isInDebug(k) ? 'source' : 'min']})

    const santaPaths = config2.paths
    Object.keys(santaPaths).forEach(
        k => {
            const def = santaPaths[k]
            // if (typeof def === 'object' && !Array.isArray(def)) {
            //     santaPaths[k] = def
            // santaPaths[k] = santaPaths[k][isInDebug(k) ? 'source' : 'min']
            // }
            if (!/^(?:\/\/)|(?:http)/.test(def)) {
                santaPaths[k] = `${santaBase}/${def}`
            }
        })

    Object.keys(config2.packages).forEach(
        k => {
            const pkg = config2.packages[k]
            pkg.location = `${santaBase}/${pkg.location}`
        })

    config.paths = Object.assign({}, santaPaths, config.paths)
    // if (local) {
    //     const {fixPaths} = require('santa-main-r/lib/lib/common/resolve')
    //     config.paths = fixPaths(boltBase, config.paths)
    // }
    config.map = Object.assign({}, santaConf.map, config.map)
    config.shim = Object.assign({}, santaConf.shim, config.shim)
    config.packages = [...santaConf.packages, config2.packages]
    config.bundles = Object.assign({}, santaConf.bundles, config2.bundles)

    return config
}

module.exports = {
    getBoltRjsConfig: getRjsConfig('bolt-main'),
    getBoltRjsConfigWithDs: getRjsConfig('bolt-main-ds'),
    getResponsiveRjsConfig: getRjsConfig('bolt-main-responsive')
}
